import { Box, Button, Link, Typography } from "@material-ui/core"
import React from "react"
import Lottie from "react-lottie"
import animationData from "../animations/meeting-left.json"
import useIsLGDesktop from "../utils/useIsLGDesktop"
import useIsSMDesktop from "../utils/useIsSMDesktop"
import useResponsiveSize from "../utils/useResponsiveSize"

const MeetingLeftScreen = ({ setMeetingLeft, leftScreenActionButtonLabel, leftScreenActionButtonHref, leftScreenRejoinButtonEnabled }) => {
	const defaultOptions = {
		loop: false,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
		},
	}

	const jsonSize = useResponsiveSize({
		xl: 720,
		lg: 640,
		md: 520,
		sm: 480,
		xs: 360,
	})

	const isSMDesktop = useIsSMDesktop()
	const isLGDesktop = useIsLGDesktop()

	return (
		<div
			style={{
				height: "100vh",
				width: "100vw",
				position: "relative",
				overflow: "hidden",
			}}
		>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					height: "100%",
				}}
			>
				<div style={{ width: jsonSize }}>
					{/* <Lottie
            options={defaultOptions}
            eventListeners={[{ eventName: "done" }]}
            width={jsonSize}
            isClickToPauseDisabled
          /> */}
				</div>
				<Box mt={6}>
					<Typography variant={"h4"} style={{ fontWeight: "bold", color: "#fff" }}>
						Poistuit kokouksesta!
					</Typography>
				</Box>
				<Box
					mt={3}
					style={{
						display: "flex",
						flexDirection: isSMDesktop || isLGDesktop ? "row" : "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					{/* {leftScreenRejoinButtonEnabled && (
						<Button
							onClick={() => {
								setMeetingLeft(false)
							}}
							size={isSMDesktop || isLGDesktop ? "large" : "medium"}
							variant="contained"
							color={"primary"}
							style={{ textTransform: "capitalize", fontWeight: "bold", color: "#fff" }}
						>
							Liity kokoukseen uudestaan
						</Button>
					)} */}
					{leftScreenActionButtonLabel &&
					leftScreenActionButtonLabel !== "undefined" &&
					leftScreenActionButtonHref &&
					leftScreenActionButtonHref !== "undefined" ? (
						<Box
							mt={leftScreenRejoinButtonEnabled ? (isSMDesktop || isLGDesktop ? 0 : 2) : 0}
							ml={leftScreenRejoinButtonEnabled ? (isSMDesktop || isLGDesktop ? 3 : 0) : 0}
						>
							<Link target={"_top"} href={leftScreenActionButtonHref}>
								<Button size={isSMDesktop || isLGDesktop ? "large" : "medium"} variant="outlined" style={{ fontWeight: "bold" }}>
									{leftScreenActionButtonLabel}
								</Button>
							</Link>
						</Box>
					) : null}
				</Box>
			</div>
		</div>
	)
}

export default MeetingLeftScreen
