import * as React from "react"

const KickoutUserIcon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={15} height={15} viewBox="0 0 15 15" {...props}>
		<path
			d="M15.8 17.818h-3.936a.985.985 0 0 1 0-1.97H15.8a.985.985 0 0 1 0 1.97Zm-6.894-.985a2.955 2.955 0 0 0 2.955 2.955H3.985A.985.985 0 0 1 3 18.8c0-1.967 1.9-3.8 3.85-4.531a3.928 3.928 0 0 1-1.88-3.345v-.985a3.939 3.939 0 1 1 7.879 0v.985a3.88 3.88 0 0 1-1.453 3 2.947 2.947 0 0 0-2.487 2.909Z"
			transform="translate(-3 -6)"
			style={{
				fill: "#000",
			}}
		/>
	</svg>
)

export default KickoutUserIcon
