import { responsiveFontSizes } from "@material-ui/core/styles"
import { createTheme } from "@material-ui/core/styles"

export default function generateMuiTheme(type, primary, secondary) {
	return responsiveFontSizes(
		createTheme({
			typography: {
				fontFamily: ["Poppins"].join(","),
			},

			palette: {
				type: "light",
				text: { primary: "#000", secondary: "#565656" },
				success: { main: "#4aa96c" },
				error: { main: "#D32F2F" },
				divider: "#9d9bbc",
				background: {
					default: "#fff",
					paper: "#fff",
				},
				primary: {
					main: "#7FC646",
					contrastText: "#151515",
					light: "#70d273",
					dark: "#4ca239",
				},
				secondary: {
					main: "#FEAD2D",
					light: "#ffe3b8",
					dark: "#af6903",
					contrastText: "#151515",
				},
				// primary: {
				//   main: "#1178F8",
				// },
				// secondary: {
				//   main: "#000",
				//   contrastText: "#fff",
				// },
				common: { white: "#fff", black: "#000", sidePanel: "#3D3C4E" },
			},
			overrides: {
				MuiPaper: {
					rounded: { borderRadius: "10px" },
				},
				MuiTypography: {
					root: { color: "#000" },
				},
				MuiTooltip: {
					tooltip: {
						fontSize: "1rem",
						color: "#fff",
						backgroundColor: "#505050",
					},
				},
			},
		})
	)
}

// #22c3f3
