import * as React from "react";

function UnPin(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" {...props}>
      <path d="M15.6 35.7L40 14.2V9.1h.7c1.1 0 2-.4 2.7-1.1.7-.7 1.1-1.6 1.1-2.7 0-2.1-1.7-3.8-3.8-3.8H19.2c-2.1 0-3.8 1.7-3.8 3.8 0 1 .4 2 1.1 2.7.7.7 1.7 1.1 2.7 1.1h.7V28l-5.1 6.1c-.2.3-.3.7-.1 1.1.2.3.9.5.9.5zM29 58.8c.1.4.5.7 1 .8.3 0 .5-.1.7-.3.1-.1.2-.3.3-.5l4.7-18.1H24.4L29 58.8z" />
      <path d="M54 13.3c.6-.5.6-1.4.1-2l-.9-1-.7-.8c-.4-.5-1.2-.5-1.7-.1L5.2 49.5c-.5.4-.6 1.2-.1 1.7l1.1 1.3c.1.2.3.3.5.3.5.6 1.4.7 2 .1l17.5-15.4h18.1c.3 0 .5-.1.7-.3.1-.1.1-.2.2-.3.2-.4.1-.8-.1-1.1l-5-5.8v-4.4L54 13.3z" />
    </svg>
  );
}

export default UnPin;
